//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { gmapApi } from 'vue2-google-maps';
export default {
  name: 'MapPicker',
  props: {
    location: {
      type: Object,
      default: () => ({})
    },
    visible: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      place: null,
      description: ''
    };
  },
  computed: {
    google: gmapApi,
    coords() {
      return this.place || { lat: 35.6580939, lng: 139.7413553 };
    }
  },
  mounted() {
    if (this.location.lat) {
      this.place = { ...this.location };
      return;
    }
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        const { coords } = position;
        this.place = {
          lat: coords.latitude,
          lng: coords.longitude
        };
      });
    } else {
      this.setError('Geolocation is not supported by this browser.');
    }
  },
  methods: {
    setPlace(place) {
      this.place = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
        pointName: place.name
      };
    },
    handleOk() {
      // if (!this.place.pointName) {
      //   const location = this.place;
      //   delete location.pointName;
      //   const geocoder = new this.google.maps.Geocoder();
      //   geocoder.geocode({ location: location }, (results, status) => {
      //     if (status === "OK") {
      //       if (results[0]) {
      //         this.place = { ...this.place, pointName: results[0].formatted_address };
      //       }
      //     }
      //     this.$emit("setLocation", this.place);
      //   });
      // } else {
      //   this.$emit("setLocation", this.place);
      // }
      this.$emit('setLocation', this.place);
    },
    handleCancel() {
      this.$emit('close');
    },
    setLocation(center) {
      this.place = {
        lat: center.latLng.lat(),
        lng: center.latLng.lng()
      };
    }
  }
};
