var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-modal",
        {
          attrs: {
            width: "740px",
            visible: _vm.visible,
            "destroy-on-close": "",
            title: _vm.$t("Pick a location")
          },
          on: { ok: _vm.handleOk, cancel: _vm.handleCancel }
        },
        [
          _c(
            "div",
            { staticClass: "content-between" },
            [
              _c("div", [_vm._v(_vm._s(_vm.$t("Your location")))]),
              _c("gmap-autocomplete", {
                staticStyle: { width: "85%" },
                attrs: { placeholder: _vm.$t("Enter a location") },
                on: { place_changed: _vm.setPlace }
              })
            ],
            1
          ),
          _c("div"),
          _c(
            "div",
            { staticClass: "my-md" },
            [
              _c(
                "GmapMap",
                {
                  staticStyle: { width: "100%", height: "400px" },
                  attrs: {
                    "map-type-id": "roadmap",
                    zoom: 18,
                    options: {
                      zoomControl: true,
                      draggable: true
                    },
                    center: _vm.coords
                  }
                },
                [
                  _c("GmapMarker", {
                    attrs: { label: "★", position: _vm.place, draggable: true },
                    on: { dragend: _vm.setLocation }
                  })
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }